/* Navigation search form */
jQuery(document).ready(function ($) {
  var breakpoint = 769; // Define the breakpoint for mobile vs desktop

  // Hide the input field and form initially
  $("#s").hide();
  $("#search-form").hide();

  $("#search-submit").click(function (event) {
    event.stopPropagation(); // Prevent the click event from propagating to the document

    var input = $("#s");
    var form = $("#search-form");

    // Toggle the form and input field when search button is clicked
    if (form.css("max-height") === "0px" || form.css("display") === "none") {
      form.show();
      setTimeout(function () {
        form.css({ maxHeight: "4rem" }); // Set max-height to match height including padding
      }, 10);
      input.show();
      if ($(window).width() < breakpoint) {
        form.css("display", "flex"); // Mobile view
        input.css("width", "100%").focus(); // Mobile view
      } else {
        form.css("display", "flex"); // Desktop view
        input.css("width", "50%").focus(); // Desktop view
      }
    } else {
      form.css({ maxHeight: "0" });
      setTimeout(() => {
        form.hide();
        input.hide().css("width", "0px");
      }, 300); // Allow time for the transition before hiding
    }
  });

  $(document).click(function (event) {
    var input = $("#s");
    var form = $("#search-form");
    var button = $("#search-submit");

    // Check if the clicked area is outside the input and button
    if (
      !input.is(event.target) &&
      !button.is(event.target) &&
      input.has(event.target).length === 0 &&
      button.has(event.target).length === 0
    ) {
      form.css({ maxHeight: "0" });
      setTimeout(() => {
        form.hide();
        input.hide().css("width", "0px").val(""); // Hide and collapse the input
      }, 300); // Allow time for the transition before hiding
    }
  });

  // Prevent the document click event from firing when clicking on the input
  $("#s").click(function (event) {
    event.stopPropagation();
  });
});
