/**
 * File unified.js.
 *
 * Combines functionalities from navigation.js and custom.js.
 * Handles both basic navigation functionalities and enhanced jQuery features for responsive design.
 */

// Vanilla JavaScript for basic navigation functionalities
(function () {
  const siteNavigation = document.getElementById("site-navigation");
  if (!siteNavigation) {
    // console.error("siteNavigation is not found.");
    return;
  }

  const button = siteNavigation.getElementsByTagName("button")[0];
  const menu = siteNavigation.getElementsByTagName("ul")[0];
  if (!button || !menu) {
    if (button) button.style.display = "none";
    // console.error("Button or menu is not found.");
    return;
  }

  if (!menu.classList.contains("nav-menu")) {
    menu.classList.add("nav-menu");
  }

  const links = menu.getElementsByTagName("a");
  const linksWithChildren = menu.querySelectorAll(
    ".menu-item-has-children > a, .page_item_has_children > a"
  );

  for (const link of links) {
    link.addEventListener("focus", toggleFocus, true);
    link.addEventListener("blur", toggleFocus, true);
  }

  for (const link of linksWithChildren) {
    link.addEventListener(
      "touchstart",
      function (event) {
        let target = event.target;
        if (
          target.classList.contains("dropdown-toggle") ||
          target.parentNode.classList.contains("dropdown-toggle")
        ) {
          event.preventDefault(); // Prevent default to stop the link navigation
          let submenu = this.parentNode.querySelector(".dropdown-menu");
          if (!submenu) {
            console.error("Submenu not found.");
            return;
          }
          submenu.classList.toggle("visible"); // Toggle visibility
        }
      },
      false
    );
  }

  function toggleFocus(event) {
    let self = this;
    if (event.type === "focus" || event.type === "blur") {
      while (!self.classList.contains("nav-menu")) {
        if (self.tagName.toLowerCase() === "li") {
          self.classList.toggle("focus");
        }
        self = self.parentNode;
      }
    }
  }
})();

// jQuery for advanced interactive elements and responsive enhancements
jQuery(document).ready(function ($) {
  function bindDropdownEvents() {
    $(".dropdown-toggle").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();
      var $submenu = $(this).closest(".nav-item").find(".dropdown-menu:first");
      if (!$submenu.length) {
        console.error("Dropdown menu not found");
        return;
      }
      $submenu.toggleClass("slide-in");
    });
  }

  bindDropdownEvents();

  $(".dropdown-menu").each(function () {
    var $parentLink = $(this).closest(".nav-item").find("> a.nav-link");
    var parentItemName = $parentLink.text();
    var parentItemHref = $parentLink.attr("href");

    $(this).prepend(
      '<li class="back-button d-xl-none d-flex align-items-center justify-content-center">' +
        '<a href="#" class="back-action d-flex align-items-center justify-content-start">' +
        '<span class="back-image"></span>' +
        "</a>" +
        '<a href="' +
        parentItemHref +
        '" class="parent-link d-flex align-items-center justify-content-start text-decoration-none">' +
        '<span class="text-uppercase">' +
        parentItemName +
        "</span>" +
        "</a>" +
        "</li>"
    );
  });

  $(document).on("click", ".back-button .back-action", function (e) {
    e.preventDefault();
    $(this).closest(".dropdown-menu").removeClass("slide-in");
  });

  const body = document.body;
  const navbarNavDropdown = document.getElementById("navbar-nav-dropdown");

  navbarNavDropdown.addEventListener("show.bs.collapse", () => {
    body.classList.add("no-scroll");
  });

  navbarNavDropdown.addEventListener("hide.bs.collapse", () => {
    body.classList.remove("no-scroll");
  });
});
