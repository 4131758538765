(function () {
  const switcherNavs = document.querySelectorAll(".mlp-language-switcher-nav");
  if (!switcherNavs.length) return;

  let idCounter = 0;

  function createDropdown(container) {
    const ul = container.querySelector("ul");
    if (!ul) return;
    ul.style.display = "none";

    const dropdownWrapper = document.createElement("div");
    dropdownWrapper.style.position = "relative";
    dropdownWrapper.style.display = "inline-block";

    const currentItem = ul.querySelector(
      ".mlp-language-switcher-item--current-site a"
    );
    const currentFlag = currentItem
      ?.querySelector(".mlp-site-flag-icon")
      ?.cloneNode(true);

    const button = document.createElement("button");
    const dropdownId = `mlp-dropdown-${idCounter++}`;
    button.setAttribute("aria-haspopup", "true");
    button.setAttribute("aria-expanded", "false");
    button.setAttribute("aria-controls", dropdownId);
    button.type = "button"; // prevent form submission if inside a form
    button.style.display = "flex";
    button.style.alignItems = "center";
    button.style.gap = "0.5em";
    button.style.background = "none";
    button.style.cursor = "pointer";

    if (currentFlag) {
      button.appendChild(currentFlag);
    }

    const arrow = document.createElement("span");
    arrow.className = "language-arrow";
    arrow.style.display = "inline-block";
    arrow.style.width = "12px";
    arrow.style.height = "6px";
    arrow.style.backgroundImage =
      "url(\"data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99609 2L6.16276 4.5L10.3294 2' stroke='%2312193d' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E\")";
    arrow.style.backgroundRepeat = "no-repeat";
    arrow.style.transition = "transform 0.3s ease";
    button.appendChild(arrow);

    dropdownWrapper.appendChild(button);

    const dropdownList = document.createElement("div");
    dropdownList.className = "custom-language-dropdown";
    dropdownList.style.display = "none";
    dropdownList.setAttribute("role", "menu");
    dropdownList.setAttribute("id", dropdownId);
    dropdownList.setAttribute("aria-labelledby", dropdownId + "-label");

    const liElements = ul.querySelectorAll("li");
    const items = [];

    liElements.forEach((li) => {
      const link = li.querySelector("a");
      if (!link) return;

      // Normalize the link URL by removing trailing slashes.
      let url = link.href.replace(/\/+$/, "");

      // Also normalize the public site URLs.
      const publicUrls = mySiteData.publicSiteUrls.map((u) =>
        u.replace(/\/+$/, "")
      );

      // If the URL is not found among public URLs, skip processing this li.
      if (publicUrls.indexOf(url) === -1) {
        return;
      }

      const item = document.createElement("div");
      item.setAttribute("role", "menuitem");
      item.tabIndex = -1;
      item.style.cursor = "pointer";

      const flag = link.querySelector(".mlp-site-flag-icon");
      if (flag) {
        const clonedFlag = flag.cloneNode(true);
        item.appendChild(clonedFlag);
        item.appendChild(document.createTextNode(" "));
      }

      const text = link.textContent.trim();
      const match = text.match(/\((.*?)\)/);
      const country = match ? match[1] : text;

      item.appendChild(document.createTextNode(country));
      item.addEventListener("click", () => {
        window.location.href = link.href;
      });

      // dropdownList.appendChild(item);
      items.push(item);
    });

    // 1) Sort the items array by the textContent
    items.sort((a, b) => {
      const textA = a.textContent.trim().toLowerCase();
      const textB = b.textContent.trim().toLowerCase();
      return textA.localeCompare(textB);
    });

    // 2) Now append each item in sorted order
    items.forEach((item) => {
      dropdownList.appendChild(item);
    });

    dropdownWrapper.appendChild(dropdownList);
    container.appendChild(dropdownWrapper);

    function openDropdown() {
      dropdownList.style.display = "block";
      dropdownList.style.visibility = "hidden";

      const wrapperRect = dropdownWrapper.getBoundingClientRect();
      const dropdownWidth = dropdownList.offsetWidth;
      const spaceBelow = window.innerHeight - wrapperRect.bottom;
      const spaceAbove = wrapperRect.top;

      if (
        spaceBelow < dropdownList.scrollHeight &&
        spaceAbove > dropdownList.scrollHeight
      ) {
        dropdownList.style.bottom = `${dropdownWrapper.offsetHeight}px`;
        dropdownList.style.top = "auto";
      } else {
        dropdownList.style.top = `${dropdownWrapper.offsetHeight}px`;
        dropdownList.style.bottom = "auto";
      }

      const spaceRight = window.innerWidth - wrapperRect.left;
      if (spaceRight < dropdownWidth) {
        dropdownList.style.right = "0";
        dropdownList.style.left = "auto";
      } else {
        dropdownList.style.left = "0";
        dropdownList.style.right = "auto";
      }

      dropdownList.style.visibility = "visible";
      requestAnimationFrame(() => {
        dropdownList.classList.add("custom-language-dropdown--visible");
        button.setAttribute("aria-expanded", "true");
        arrow.style.transform = "rotate(180deg)";
      });
    }

    function closeDropdown() {
      dropdownList.classList.remove("custom-language-dropdown--visible");
      button.setAttribute("aria-expanded", "false");
      arrow.style.transform = "rotate(0deg)";
      setTimeout(() => {
        dropdownList.style.display = "none";
      }, 250);
    }

    button.addEventListener("click", () => {
      const isOpen = dropdownList.classList.contains(
        "custom-language-dropdown--visible"
      );
      isOpen ? closeDropdown() : openDropdown();
    });

    button.addEventListener("keydown", (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault();
        if (
          !dropdownList.classList.contains("custom-language-dropdown--visible")
        )
          openDropdown();
        items[0]?.focus();
      }
    });

    dropdownList.addEventListener("keydown", (e) => {
      const currentIndex = items.indexOf(document.activeElement);
      if (e.key === "ArrowDown") {
        e.preventDefault();
        const next = items[currentIndex + 1] || items[0];
        next.focus();
      } else if (e.key === "ArrowUp") {
        e.preventDefault();
        const prev = items[currentIndex - 1] || items[items.length - 1];
        prev.focus();
      } else if (e.key === "Escape") {
        e.preventDefault();
        closeDropdown();
        button.focus();
      } else if (e.key === "Enter" || e.key === " ") {
        e.preventDefault();
        document.activeElement.click();
      }
    });

    document.addEventListener("click", (e) => {
      if (!dropdownWrapper.contains(e.target)) {
        if (
          dropdownList.classList.contains("custom-language-dropdown--visible")
        ) {
          closeDropdown();
        }
      }
    });
  }

  switcherNavs.forEach((nav) => createDropdown(nav));
})();

document.querySelectorAll(".mlp-language-switcher-nav").forEach((nav) => {
  const isInFooter = nav.closest("footer, .site-footer, #footer");

  const isInMobileExtra = nav.closest(".mobile-extra-links");

  // White globe
  const duskBlueSVG =
    "data:image/svg+xml,%3Csvg%20version%3D%221.0%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2296.000000pt%22%20height%3D%2296.000000pt%22%20viewBox%3D%220%200%2096.000000%2096.000000%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%3E%3Cg%20transform%3D%22translate(0.000000%2C96.000000)%20scale(0.100000%2C-0.100000)%22%20fill%3D%22%237378a5%22%20stroke%3D%22none%22%3E%3Cpath%20d%3D%22M386%20870%20c-63%20-16%20-153%20-70%20-197%20-117%20-22%20-24%20-55%20-74%20-72%20-111%20-29-61-32-76-32-163%200-90%202-99%2037-171%2045-91%20103-147%20196-191%2061-29%2076-32%20162-32%2086%200%20101%203%20162%2032%2093%2044%20151%20100%20196%20191%2035%2072%2037%2081%2037%20172%200%2091-2%20100-37%20172-68%20136-188%20217-336%20224-42%202-94-1-116-6z%20m124-109%20c11-22%2024-58%2029-80%20l9-41-68%200-68%200%209%2041%20c11%2049%2046%20119%2059%20119%205%200%2019-18%2030-39z%20m-150-8%20c-5-16-14-47-20-70%20l-11-43-59%200%20c-33%200-60%203-60%206%200%2026%20123%20134%20152%20134%204%200%203-12-2-27z%20m307-15%20c37-25%2083-76%2083-92%200-3-27-6-60-6%20l-59%200-11%2043%20c-6%2023-15%2054-20%2069-10%2032%203%2030%2067-14z%20m-347-258%20l0-80-74%200%20c-84%200-86%202-86%2080%200%2078%202%2080%2086%2080%20l74%200%200-80z%20m240%200%20l0-80-80%200-80%200%200%2080%200%2080%2080%200%2080%200%200-80z%20m234%2064%20c8-20%208-108%200-128-5-13-22-16-80-16%20l-74%200%200%2080%200%2080%2074%200%20c58%200%2075-3%2080-16z%20m-454-266%20c6-24%2015-55%2020-70%207-23%206-26-8-21-50%2018-142%20101-142%20127%200%203%2027%206%2060%206%20l59%200%2011-42z%20m199%201%20c-11-49-46-119-59-119-13%200-48%2070-59%20119%20l-9%2041%2068%200%2068%200-9-41z%20m211%2035%20c0-26-123-134-152-134-4%200-3%2012%202%2028%205%2015%2014%2046%2020%2070%20l11%2042%2059%200%20c33%200%2060-3%2060-6z%22/%3E%3C/g%3E%3C/svg%3E";

  // White arrow
  const duskBlueArrow =
    "url(\"data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99609 2L6.16276 4.5L10.3294 2' stroke='%237378a5' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E\")";

  // Grey globe (#828282)
  const greySVG =
    "data:image/svg+xml,%3Csvg%20version%3D%221.0%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2296.000000pt%22%20height%3D%2296.000000pt%22%20viewBox%3D%220%200%2096.000000%2096.000000%22%20preserveAspectRatio%3D%22xMidYMid%20meet%22%3E%3Cg%20transform%3D%22translate(0.000000%2C96.000000)%20scale(0.100000%2C-0.100000)%22%20fill%3D%22%23828282%22%20stroke%3D%22none%22%3E%3Cpath%20d%3D%22M386%20870%20c-63%20-16%20-153%20-70%20-197%20-117%20-22%20-24%20-55%20-74%20-72%20-111%20-29-61-32-76-32-163%200-90%202-99%2037-171%2045-91%20103-147%20196-191%2061-29%2076-32%20162-32%2086%200%20101%203%20162%2032%2093%2044%20151%20100%20196%20191%2035%2072%2037%2081%2037%20172%200%2091-2%20100-37%20172-68%20136-188%20217-336%20224-42%202-94-1-116-6z%20m124-109%20c11-22%2024-58%2029-80%20l9-41-68%200-68%200%209%2041%20c11%2049%2046%20119%2059%20119%205%200%2019-18%2030-39z%20m-150-8%20c-5-16-14-47-20-70%20l-11-43-59%200%20c-33%200-60%203-60%206%200%2026%20123%20134%20152%20134%204%200%203-12-2-27z%20m307-15%20c37-25%2083-76%2083-92%200-3-27-6-60-6%20l-59%200-11%2043%20c-6%2023-15%2054-20%2069-10%2032%203%2030%2067-14z%20m-347-258%20l0-80-74%200%20c-84%200-86%202-86%2080%200%2078%202%2080%2086%2080%20l74%200%200-80z%20m240%200%20l0-80-80%200-80%200%200%2080%200%2080%2080%200%2080%200%200-80z%20m234%2064%20c8-20%208-108%200-128-5-13-22-16-80-16%20l-74%200%200%2080%200%2080%2074%200%20c58%200%2075-3%2080-16z%20m-454-266%20c6-24%2015-55%2020-70%207-23%206-26-8-21-50%2018-142%20101-142%20127%200%203%2027%206%2060%206%20l59%200%2011-42z%20m199%201%20c-11-49-46-119-59-119-13%200-48%2070-59%20119%20l-9%2041%2068%200%2068%200-9-41z%20m211%2035%20c0-26-123-134-152-134-4%200-3%2012%202%2028%205%2015%2014%2046%2020%2070%20l11%2042%2059%200%20c33%200%2060-3%2060-6z%22/%3E%3C/g%3E%3C/svg%3E";

  // Grey arrow (#828282)
  const greyArrow =
    "url(\"data:image/svg+xml,%3Csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.99609 2L6.16276 4.5L10.3294 2' stroke='%23828282' stroke-width='2' stroke-linecap='square'/%3E%3C/svg%3E\")";

  let colorSVG;
  let arrowColor;

  if (isInFooter) {
    // White Globe + White Arrow
    colorSVG = duskBlueSVG;
    arrowColor = duskBlueArrow;
  } else if (isInMobileExtra) {
    // Grey Globe + Grey Arrow
    colorSVG = greySVG;
    arrowColor = greyArrow;
  } else {
    // No changes in other areas
    return;
  }

  // Replace only the globe/flag in the button
  const buttonFlagImg = nav.querySelector(
    "button img.mlp-site-flag-icon--custom"
  );
  if (buttonFlagImg) {
    buttonFlagImg.src = colorSVG;
  }

  // Replace arrow
  const buttonArrow = nav.querySelector("button .language-arrow");
  if (buttonArrow && arrowColor) {
    buttonArrow.style.backgroundImage = arrowColor;
  }

  // Optional: also update the original item list for that site
  const originalFlagImg = nav.querySelector(
    ".mlp-language-switcher-item--current-site img.mlp-site-flag-icon--custom"
  );
  if (originalFlagImg) {
    originalFlagImg.src = colorSVG;
  }
});

/* change string for global */
document.querySelectorAll(".custom-language-dropdown").forEach((dropdown) => {
  dropdown.querySelectorAll("[role='menuitem']").forEach((item) => {
    const img = item.querySelector("img.mlp-site-flag-icon--custom");
    if (img) {
      // Find the text node and change its value
      const textNode = Array.from(item.childNodes).find(
        (node) => node.nodeType === Node.TEXT_NODE && node.textContent.trim()
      );
      if (textNode) {
        textNode.textContent = " Global";
      }
    }
  });
});
